<template>
  <div id="mailmarketing">
    <div v-if="ready">
      <vs-alert v-if="!configMail" class="mt-2" color="primary" title="Orientações:" :active="!configMail" icon-pack="feather">
        <p>Para enviar emails é necessário configurar sua conta de email.</p>
        <p>Siga até o menu de configurações, acesse a aba Geral e dentro desta nova tela, acesse a aba E-mail.</p>
        <p>Efetue as configurações e retorne para efetuar o envio de e-mails.</p>
      </vs-alert>

      <div v-else class="vx-card p-6">
        <vx-card>
          <div slot="no-body" class="tabs-container px-6 pt-6">
            <vs-tabs class="tab-action-btn-fill-conatiner">
              <vs-tab label="CNH" icon-pack="feather" icon="icon-sliders">
                <div class="tab-text">
                  <cnh class="mt-4" />
                </div>
              </vs-tab>
              <vs-tab label="Filtro Geral" icon-pack="feather" icon="icon-info">
                <div class="tab-text">
                  <general class="mt-4" />
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>

import General    from './tabs/General/General.vue'
import Cnh        from './tabs/Cnh/Cnh.vue'

export default {
  components: {
    General,
    Cnh
  },
  data () {
    return {
      configMail: false,
      ready: false
    }
  },
  methods: {
    async checkMailConfig () {
      this.$vs.loading()
      const configMail = await this.$http.get(`${process.env.VUE_APP_API_URL}/email/config`)
      this.$nextTick(() => { this.$vs.loading.close() })
      this.configMail = !!configMail.data.id
      this.ready = true
    }
  },
  created () {
    this.checkMailConfig()
  }
}
</script>
