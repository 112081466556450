var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "mailmarketing-cnh-tab" } },
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-full w-full mt-0" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.initial",
                  value: "required",
                  expression: "'required'",
                  modifiers: { initial: true },
                },
              ],
              staticClass: "w-full mt-0",
              attrs: {
                label: "Assunto",
                "data-vv-as": "Assunto",
                name: "subject",
              },
              model: {
                value: _vm.mail.subject,
                callback: function ($$v) {
                  _vm.$set(_vm.mail, "subject", $$v)
                },
                expression: "mail.subject",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("subject"),
                    expression: "errors.has('subject')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("subject")))]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col md:w-full w-full mt-2" }, [
          _c(
            "div",
            { staticClass: "example-mailmarketing-cnh-tab" },
            [
              _c("quill-editor", {
                ref: "documentTemplateEditor",
                staticClass: "editor",
                attrs: { options: _vm.editorOption },
                model: {
                  value: _vm.mail.message,
                  callback: function ($$v) {
                    _vm.$set(_vm.mail, "message", $$v)
                  },
                  expression: "mail.message",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4 w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.initial",
                  value: "required",
                  expression: "'required'",
                  modifiers: { initial: true },
                },
              ],
              attrs: {
                type: "date",
                label: "Intervalo Inicial de Vencimento",
                "data-vv-as": "Intervalo Inicial",
                name: "firstPeriod",
              },
              model: {
                value: _vm.mail.firstPeriod,
                callback: function ($$v) {
                  _vm.$set(_vm.mail, "firstPeriod", $$v)
                },
                expression: "mail.firstPeriod",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("firstPeriod"),
                    expression: "errors.has('firstPeriod')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("firstPeriod")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4 w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.initial",
                  value: "required",
                  expression: "'required'",
                  modifiers: { initial: true },
                },
              ],
              attrs: {
                type: "date",
                label: "Intervalo Final de Vencimento",
                "data-vv-as": "Intervalo Final",
                name: "lastPeriod",
              },
              model: {
                value: _vm.mail.lastPeriod,
                callback: function ($$v) {
                  _vm.$set(_vm.mail, "lastPeriod", $$v)
                },
                expression: "mail.lastPeriod",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("lastPeriod"),
                    expression: "errors.has('lastPeriod')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("lastPeriod")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/2 w-full mt-0" },
          [
            _c(
              "vs-alert",
              {
                staticClass: "mt-2",
                attrs: {
                  color: "primary",
                  title: "Orientações:",
                  active: _vm.activeAlert,
                  closable: "",
                  "icon-pack": "feather",
                  "close-icon": "icon-x",
                },
                on: {
                  "update:active": function ($event) {
                    _vm.activeAlert = $event
                  },
                },
              },
              [
                _c("p", [
                  _vm._v(
                    "Só serão selecionados clientes que possuam a data de vencimento da CNH cadastrada e com vencimento dentro do período selecionado."
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "ml-auto mb-2",
                  attrs: { icon: "send", disabled: !_vm.validateMailForm },
                  on: { click: _vm.sendMail },
                },
                [_vm._v("Enviar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }