var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configuration-general-tab" } },
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-full w-full mt-0" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.initial",
                  value: "required",
                  expression: "'required'",
                  modifiers: { initial: true },
                },
              ],
              staticClass: "w-full mt-0",
              attrs: {
                label: "Assunto",
                "data-vv-as": "Assunto",
                name: "subject",
              },
              model: {
                value: _vm.mail.subject,
                callback: function ($$v) {
                  _vm.$set(_vm.mail, "subject", $$v)
                },
                expression: "mail.subject",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("subject"),
                    expression: "errors.has('subject')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("subject")))]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col md:w-full w-full mt-2" }, [
          _c(
            "div",
            { staticClass: "example-configuration-general-tab" },
            [
              _c("quill-editor", {
                ref: "documentTemplateEditor",
                staticClass: "editor",
                attrs: { options: _vm.editorOption },
                model: {
                  value: _vm.mail.message,
                  callback: function ($$v) {
                    _vm.$set(_vm.mail, "message", $$v)
                  },
                  expression: "mail.message",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4 w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.initial",
                  value: "required",
                  expression: "'required'",
                  modifiers: { initial: true },
                },
              ],
              staticClass: "w-full",
              attrs: {
                type: "date",
                label: "Data Inicial de Cadastro",
                "data-vv-as": "Data Inicial",
                name: "firstPeriod",
              },
              model: {
                value: _vm.mail.firstPeriod,
                callback: function ($$v) {
                  _vm.$set(_vm.mail, "firstPeriod", $$v)
                },
                expression: "mail.firstPeriod",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("firstPeriod"),
                    expression: "errors.has('firstPeriod')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("firstPeriod")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4 w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.initial",
                  value: "required",
                  expression: "'required'",
                  modifiers: { initial: true },
                },
              ],
              staticClass: "w-full",
              attrs: {
                type: "date",
                label: "Data Final de Cadastro",
                "data-vv-as": "Data Final",
                name: "lastPeriod",
              },
              model: {
                value: _vm.mail.lastPeriod,
                callback: function ($$v) {
                  _vm.$set(_vm.mail, "lastPeriod", $$v)
                },
                expression: "mail.lastPeriod",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("lastPeriod"),
                    expression: "errors.has('lastPeriod')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("lastPeriod")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4 w-full mt-0" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Situação de Contrato"),
            ]),
            _c("v-select", {
              staticClass: "vue_select_drop_size_90",
              attrs: {
                reduce: (option) => option.value,
                options: [
                  { value: "IN_PROGRESS", label: "Em Andamento" },
                  { value: "FINISHED", label: "Finalizado" },
                  { value: "CANCELED", label: "Cancelado" },
                ],
                placeholder: "TODOS",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n            Nenhum resultado encontrado.\n          "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.mail.situation_contract,
                callback: function ($$v) {
                  _vm.$set(_vm.mail, "situation_contract", $$v)
                },
                expression: "mail.situation_contract",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4 w-full mt-0" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Aniversário"),
            ]),
            _c("v-select", {
              staticClass: "vue_select_drop_size_160",
              attrs: {
                appendToBody: "",
                reduce: (option) => option.value,
                clearable: true,
                options: [
                  { value: "01", label: "JANEIRO" },
                  { value: "02", label: "FEVEREIRO" },
                  { value: "03", label: "MARÇO" },
                  { value: "04", label: "ABRIL" },
                  { value: "05", label: "MAIO" },
                  { value: "06", label: "JUNHO" },
                  { value: "07", label: "JULHO" },
                  { value: "08", label: "AGOSTO" },
                  { value: "09", label: "SETEMBRO" },
                  { value: "10", label: "OUTUBRO" },
                  { value: "11", label: "NOVEMBRO" },
                  { value: "12", label: "DEZEMBRO" },
                ],
                placeholder: "TODOS",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n            Desculpe, nenhum resultado encontrado.\n          "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.mail.birthday,
                callback: function ($$v) {
                  _vm.$set(_vm.mail, "birthday", $$v)
                },
                expression: "mail.birthday",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-full w-full" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Aluno(s)"),
            ]),
            _c("v-select", {
              attrs: {
                appendToBody: "",
                multiple: "",
                clearable: false,
                filterable: true,
                reduce: (option) => option.value,
                options: _vm.studentOptions,
                placeholder: "Digite CPF ou nome do aluno...",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              on: { search: _vm.debouncedGetSearchStudent },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n            Nenhum resultado encontrado.\n          "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.mail.students,
                callback: function ($$v) {
                  _vm.$set(_vm.mail, "students", $$v)
                },
                expression: "mail.students",
              },
            }),
          ],
          1
        ),
      ]),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "ml-auto mb-4",
                  attrs: { icon: "send", disabled: !_vm.validateForm },
                  on: { click: _vm.sendMail },
                },
                [_vm._v("Enviar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }