<template>
  <div id="mailmarketing-cnh-tab">
      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <vs-input class="w-full mt-0" label="Assunto" v-model="mail.subject" data-vv-as="Assunto" v-validate.initial="'required'" name="subject" />
          <span class="text-danger text-sm" v-show="errors.has('subject')">{{ errors.first('subject') }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-2">
          <div class="example-mailmarketing-cnh-tab">
            <quill-editor class="editor" ref="documentTemplateEditor" v-model="mail.message" :options="editorOption" />
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full">
          <vs-input type="date" v-model="mail.firstPeriod" label="Intervalo Inicial de Vencimento"
            data-vv-as="Intervalo Inicial" v-validate.initial="'required'" name="firstPeriod" />
          <span class="text-danger text-sm" v-show="errors.has('firstPeriod')">{{ errors.first('firstPeriod') }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <vs-input type="date" v-model="mail.lastPeriod" label="Intervalo Final de Vencimento"
            data-vv-as="Intervalo Final" v-validate.initial="'required'" name="lastPeriod" />
          <span class="text-danger text-sm" v-show="errors.has('lastPeriod')">{{ errors.first('lastPeriod') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-alert class="mt-2" color="primary" title="Orientações:" :active.sync="activeAlert" closable icon-pack="feather" close-icon="icon-x">
            <p>Só serão selecionados clientes que possuam a data de vencimento da CNH cadastrada e com vencimento dentro do período selecionado.</p>
          </vs-alert>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mb-2" icon="send" @click="sendMail" :disabled="!validateMailForm">Enviar</vs-button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'

import quill from 'quill'

const ColorClass = quill.import('attributors/class/color')
const SizeStyle = quill.import('attributors/style/size')
const Align = quill.import('attributors/style/align')
const Background = quill.import('attributors/style/background')
const Direction = quill.import('attributors/style/direction')
const Font = quill.import('attributors/style/font')

quill.register(ColorClass, true)
quill.register(SizeStyle, true)
quill.register(Align, true)
quill.register(Background, true)
quill.register(Direction, true)
quill.register(Font, true)

export default {
  components: {
    quillEditor
  },

  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    validateMailForm () {
      return !this.errors.any()
    }
  },
  data () {
    return {
      activeAlert: true,
      mail: {
        firstPeriod: '',
        lastPeriod: '',
        situation_contract: null,
        servicePackages: [],
        birthday: null,
        subject: null,
        message: null
      },
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image', 'video']
          ]
        },
        placeholder: 'Mensagem...'
      }
    }
  },

  methods: {
    async sendMail () {

      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_mail_marketing')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('send'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.$vs.loading()
      const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/email/marketing/send/cnh`, this.mail)
      this.$nextTick(() => { this.$vs.loading.close() })

      this.$vs.notify({
        time: 5000,
        title: 'SUCESSO',
        text: resp.data.message,
        color: 'success',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    }
  },
  mounted () {

  },
  created () {

  }
}
</script>

<style lang="scss">
.example-mailmarketing-cnh-tab {
  // display: flex;
  // flex-direction: column;

  .editor {
    height: 20rem;
    overflow: hidden;
  }
  .ql-container {
    &.ql-snow {
    height: 75% !important;
      border-top: none;
      height: 24rem;
    }
  }
}

  .vs-tooltip {
    z-index: 99999;
  }
</style>
