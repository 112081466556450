<template>
  <div id="configuration-general-tab">
      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <vs-input class="w-full mt-0" label="Assunto" v-model="mail.subject" data-vv-as="Assunto" v-validate.initial="'required'" name="subject" />
          <span class="text-danger text-sm" v-show="errors.has('subject')">{{ errors.first('subject') }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-2">
          <div class="example-configuration-general-tab">
            <quill-editor class="editor" ref="documentTemplateEditor" v-model="mail.message" :options="editorOption" />
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full">
          <vs-input type="date" class="w-full" v-model="mail.firstPeriod" label="Data Inicial de Cadastro" data-vv-as="Data Inicial" v-validate.initial="'required'" name="firstPeriod" />
          <span class="text-danger text-sm" v-show="errors.has('firstPeriod')">{{ errors.first('firstPeriod') }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <vs-input type="date" class="w-full" v-model="mail.lastPeriod" label="Data Final de Cadastro" data-vv-as="Data Final" v-validate.initial="'required'" name="lastPeriod" />
          <span class="text-danger text-sm" v-show="errors.has('lastPeriod')">{{ errors.first('lastPeriod') }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Situação de Contrato</label>
          <v-select class="vue_select_drop_size_90" v-model="mail.situation_contract" :reduce="option => option.value"
            :options="[
              { value: 'IN_PROGRESS', label: 'Em Andamento'},
              { value: 'FINISHED',    label: 'Finalizado'},
              { value: 'CANCELED',    label: 'Cancelado'}
            ]" placeholder="TODOS" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Aniversário</label>
          <v-select appendToBody class="vue_select_drop_size_160" v-model="mail.birthday" :reduce="option => option.value"
            :clearable="true" :options="[
              {value: '01',  label: 'JANEIRO'},
              {value: '02',  label: 'FEVEREIRO'},
              {value: '03',  label: 'MARÇO'},
              {value: '04',  label: 'ABRIL'},
              {value: '05',  label: 'MAIO'},
              {value: '06',  label: 'JUNHO'},
              {value: '07',  label: 'JULHO'},
              {value: '08',  label: 'AGOSTO'},
              {value: '09',  label: 'SETEMBRO'},
              {value: '10', label: 'OUTUBRO'},
              {value: '11', label: 'NOVEMBRO'},
              {value: '12', label: 'DEZEMBRO'}
            ]" placeholder="TODOS" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-full w-full">
          <label class="vs-input--label">Aluno(s)</label>
          <v-select appendToBody v-model="mail.students" @search="debouncedGetSearchStudent" multiple :clearable="false" :filterable="true"
            :reduce="option => option.value" :options="studentOptions"
            placeholder="Digite CPF ou nome do aluno..." :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mb-4" icon="send" :disabled="!validateForm" @click="sendMail">Enviar</vs-button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'

import quill from 'quill'
const ColorClass = quill.import('attributors/class/color')
const SizeStyle = quill.import('attributors/style/size')
const Align = quill.import('attributors/style/align')
const Background = quill.import('attributors/style/background')
const Direction = quill.import('attributors/style/direction')
const Font = quill.import('attributors/style/font')

quill.register(ColorClass, true)
quill.register(SizeStyle, true)
quill.register(Align, true)
quill.register(Background, true)
quill.register(Direction, true)
quill.register(Font, true)

const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import _ from 'lodash'

export default {
  components: {
    quillEditor,
    vSelect
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    validateForm () {
      return !this.errors.any()
    }
  },
  data () {
    return {
      studentOptions: [],
      mail: {
        firstPeriod: '',
        lastPeriod: '',
        situation_contract: null,
        birthday: null,
        students: [],
        subject: null,
        message: null
      },
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image', 'video']
          ]
        },
        placeholder: 'Mensagem...'
      }
    }
  },

  methods: {
    async sendMail () {

      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_mail_marketing')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('send'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.$vs.loading()
      const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/email/marketing/send`, this.mail)
      this.$nextTick(() => { this.$vs.loading.close() })

      this.$vs.notify({
        time: 5000,
        title: 'SUCESSO',
        text: resp.data.message,
        color: 'success',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    },
    fetchStudentOptions (search, loading) {
      if (search.length) {
        loading(true)
        this.$http.post(`${process.env.VUE_APP_API_URL}/student/search`, { kw: search, type: 'STUDENT' })
          .then(function (response) {
            for (const k in response.data) {
              //INCLUIR SOMENTE OS ALUNOS QUE NÃO EXISTEM
              const idx = this.studentOptions.findIndex((e) => e.value === response.data[k].id)
              if (idx === -1) {
                this.studentOptions.push({
                  value: response.data[k].id,
                  label: `${response.data[k].name} ${response.data[k].cpf || ''}`
                })
              }
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    }
  },
  created () {
    this.debouncedGetSearchStudent = _.debounce(this.fetchStudentOptions, 600)
  }
}
</script>

<style lang="scss">
.example-configuration-general-tab {
  // display: flex;
  // flex-direction: column;

  .editor {
    height: 20rem;
    overflow: hidden;
  }
  .ql-container {
    &.ql-snow {
    height: 75% !important;
      border-top: none;
      height: 24rem;
    }
  }
}

  .vs-tooltip {
    z-index: 99999;
  }
</style>
