var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "mailmarketing" } }, [
    _vm.ready
      ? _c(
          "div",
          [
            !_vm.configMail
              ? _c(
                  "vs-alert",
                  {
                    staticClass: "mt-2",
                    attrs: {
                      color: "primary",
                      title: "Orientações:",
                      active: !_vm.configMail,
                      "icon-pack": "feather",
                    },
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "Para enviar emails é necessário configurar sua conta de email."
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Siga até o menu de configurações, acesse a aba Geral e dentro desta nova tela, acesse a aba E-mail."
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Efetue as configurações e retorne para efetuar o envio de e-mails."
                      ),
                    ]),
                  ]
                )
              : _c(
                  "div",
                  { staticClass: "vx-card p-6" },
                  [
                    _c("vx-card", [
                      _c(
                        "div",
                        {
                          staticClass: "tabs-container px-6 pt-6",
                          attrs: { slot: "no-body" },
                          slot: "no-body",
                        },
                        [
                          _c(
                            "vs-tabs",
                            { staticClass: "tab-action-btn-fill-conatiner" },
                            [
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label: "CNH",
                                    "icon-pack": "feather",
                                    icon: "icon-sliders",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [_c("cnh", { staticClass: "mt-4" })],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label: "Filtro Geral",
                                    "icon-pack": "feather",
                                    icon: "icon-info",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [_c("general", { staticClass: "mt-4" })],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }